import React, { useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { updateDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { connect } from "react-redux";
import { setUserInfo } from "../redux/userActions";
import LogoutButton from "./LogoutButton";

const generateUniqueId = () => {
  const timestamp = Date.now();
  const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000);
  const uniqueId = `${timestamp}${uniqueIdentifier}`;
  return uniqueId;
};

const OrganizationSelection = ({ setUserInfo }) => {
  const uid = auth.currentUser?.uid;
  const [selectedType, setSelectedType] = useState("");
  const navigate = useNavigate();

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle submission logic here
    const generatedUniqueId = generateUniqueId();
    console.log("Submitted org ID:", generatedUniqueId);

    // Create a document in the "schools" collection
    const orgRef = doc(db, "organization", generatedUniqueId);
    await setDoc(orgRef, {
      organizationType: selectedType,
    });

    try {
      const profileRef = await doc(db, "profiles", uid);
      await updateDoc(profileRef, {
        orgID: generatedUniqueId,
      });
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }

    // Fetch user data from Firestore and set it in Redux state
    const userDocSnap = await getDoc(doc(db, "profiles", uid));
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      console.log("user data on org reg form:", userData);
      setUserInfo({ uid, ...userData });
    }

    // Clear form fields after submission
    setSelectedType("");

    navigate("/zone-creation");
  };

  return (
    <div className="container mt-5">
      <h2>Select Organization Type</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="organizationType">Choose an organization type:</Label>
          <Input
            type="select"
            name="organizationType"
            id="organizationType"
            value={selectedType}
            onChange={handleTypeChange}
          >
            <option value="">Select an option</option>
            <option value="Schools and universities">
              Schools and universities
            </option>
            <option value="Office buildings and corporate campuses">
              Office buildings and corporate campuses
            </option>
            <option value="Hospitals and medical facilities">
              Hospitals and medical facilities
            </option>
            <option value="Retail stores and shopping centers">
              Retail stores and shopping centers
            </option>
            <option value="Construction sites">Construction sites</option>
            <option value="Other">Other</option>
          </Input>
        </FormGroup>
        <Button color="primary" type="submit" disabled={!selectedType}>
          Continue
        </Button>
        <LogoutButton></LogoutButton>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
  userInfo: state.user.userInfo,
});

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationSelection);

import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Container, Row, Col, Button, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { setRole } from "../redux/roleActions";
import LogoSVG from "./LogoSVG";
import "./RoleSelectionScreen.css"; // Import your custom CSS file for styling

const RoleSelectionScreen = ({ selectedRole, setRole }) => {
  const navigate = useNavigate();

  const handleRoleSelection = (role) => {
    setRole(role);
    navigate(`/${role.toLowerCase()}-signup`);
  };

  return (
    <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
      <Container
        className="justify-content-center align-items-center bg-dark text-white"
        style={{ padding: "10%", backgroundColor: "#1e1e1e" }}
      >
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card
              className="p-4"
              style={{ width: "100%", backgroundColor: "#1e1e1e" }}
            >
              <CardBody className="d-flex flex-column align-items-center text-white">
                <LogoSVG className="img-fluid" />
                <Row className="mb-3"></Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <Button
                      color="#cefbf4"
                      size="lg"
                      className={`btn-rounded btn-outline-light ${
                        selectedRole === "admin" ? "active" : ""
                      }`}
                      onClick={() => handleRoleSelection("admin")}
                    >
                      Admin
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      color="#cefbf4"
                      size="lg"
                      className={`btn-rounded btn-outline-light ${
                        selectedRole === "student" ? "active" : ""
                      }`}
                      onClick={() => handleRoleSelection("student")}
                    >
                      User
                    </Button>
                  </Col>
                </Row>
                <div className="mt-4">
                  <div className="d-flex justify-content-center">
                    <div className="mr-3">
                      <Table bordered dark responsive>
                        <thead>
                          <tr>
                            <th>Admin</th>
                            <th>User</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Manage and oversee access pass requests</td>
                            <td>Request access passes</td>
                          </tr>
                          <tr>
                            <td>View data of organization</td>
                            <td>Receive real-time updates</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedRole: state.role,
});

const mapDispatchToProps = {
  setRole,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleSelectionScreen);

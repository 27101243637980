import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { setUserInfo } from "../redux/userActions";
import { useNavigate } from "react-router-dom";
import LogoSVG from "./LogoSVG";
import InputWithPlaceholderColor from "./InputWithPlaceholderColor";
import CardButton from "./CardButton";
import checkIfOrganizationExists from "./checkIfOrganizationExists";

const SchoolRegistrationScreen = ({ role, userInfo, setUserInfo }) => {
  const uid = auth.currentUser?.uid;
  const [schoolID, setSchoolID] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSchoolID(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Check if the organization ID exists
      const orgExists = await checkIfOrganizationExists(schoolID);

      if (!orgExists) {
        // Display alert to the user that the organization ID does not exist
        alert("Organization ID does not exist");
        return; // Exit the function early
      }

      // Proceed to update the profile if the organization ID exists
      const profileRef = doc(db, "profiles", uid);
      await updateDoc(profileRef, {
        orgID: schoolID,
      });

      // Fetch user data from Firestore and set it in Redux state
      const userDocSnap = await getDoc(doc(db, "profiles", uid));
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUserInfo({ uid, ...userData });
      }
      navigate("/profile");
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  return (
    <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
      <Container
        className="justify-content-center align-items-center bg-dark text-white"
        style={{ padding: "10%", backgroundColor: "#1e1e1e" }}
      >
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card
              className="p-4"
              style={{ width: "100%", backgroundColor: "#1e1e1e" }}
            >
              <CardBody className="d-flex flex-column align-items-center text-white">
                <LogoSVG className="img-fluid" />
                <Row className="mb-3"></Row>
                <FormGroup>
                  <InputWithPlaceholderColor
                    type="text"
                    id="schoolID"
                    placeholder="Enter organization ID#"
                    value={schoolID}
                    onChange={handleChange}
                  />
                </FormGroup>
                {userInfo &&
                  (userInfo.role === "admin" ||
                    userInfo.role === "student") && (
                    <Button
                      color="#cefbf4"
                      size="lg"
                      id="TooltipProfile"
                      className="btn-rounded btn-outline-light"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                <Row className="mb-3"></Row>
                {userInfo && userInfo.role === "admin" && (
                  <CardButton
                    to="/account"
                    text="No ID#? Register Organization"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolRegistrationScreen);

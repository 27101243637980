import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../redux/userActions";
import { doc, getDoc } from "firebase/firestore";
import GoogleSignInButton from "./GoogleSignInButton";
import LogoSVG from "./LogoSVG";
import {
  auth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  db,
} from "../firebase";
import InputWithPlaceholderColor from "./InputWithPlaceholderColor";
import CardButton from "./CardButton";
import TrendySpinner from "./TrendySpinner";
import getPremiumStatus from "./getPremiumStatus";

const SignInScreen = ({ userInfo, setUserInfo }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleRouting = async (userData) => {
      try {
        if (userData.role === "student" && !userData.orgID) {
          navigate("/school-registration");
        } else if (userData.role === "admin" && !userData.orgID) {
          const isCustomer = await getPremiumStatus(auth);
          if (isCustomer) {
            navigate("/organization-selection");
          } else {
            navigate("/school-registration");
          }
        } else {
          navigate("/profile");
        }
      } catch (error) {
        console.error("Error handling routing:", error);
        navigate("/profile"); // Fallback navigation
      } finally {
        setLoading(false); // Set loading to false after handling routing
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDataFromFirestore = await fetchUserData(user.uid);
          if (userDataFromFirestore) {
            await handleRouting(userDataFromFirestore);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          navigate("/profile"); // Fallback navigation
        }
      } else {
        console.log("User is not authenticated.");
      }
    });

    return () => unsubscribe();
  }, [navigate

  ]);

  const fetchUserData = async (userId) => {
    const userDocRef = doc(db, "profiles", userId);
    const localStorageKey = `userData_${userId}`;

    // Check if user data exists in localStorage
    const cachedUserData = localStorage.getItem(localStorageKey);
    console.log("Cached user data in SignInScreen:", cachedUserData); // Debugging statement

    if (cachedUserData) {
      return JSON.parse(cachedUserData);
    }

    try {
      const userDocSnap = await getDoc(userDocRef);
      console.log(
        "User data from user doc in SignInScreen:",
        JSON.stringify(userDocSnap.data(), null, 2)
      );
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        // Store user data in localStorage for caching
        localStorage.setItem(localStorageKey, JSON.stringify(userData));
        console.log("Stored user data in SignInScreen:", userData); // Debugging statement
        return userData;
      }
    } catch (error) {
      console.error("Error fetching user data in SignInScreen:", error);
    }

    return null;
  };

  const orTextStyles = {
    fontSize: "14px", // Adjust the font size as needed
    color: "#999", // Adjust the color as needed
    marginBottom: "10px", // Adjust the margin bottom as needed
  };
  const dividerStyles = {
    borderTop: "1px solid #999", // Adjust the color and style of the divider lines as needed
    margin: "10px 0", // Adjust the margin as needed
  };

  const handleRouting = (userInfo) => {
    if (userInfo?.role === "student" && !userInfo?.orgID) {
      navigate("/school-registration");
    } else {
      navigate("/profile");
    }
  };

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await handleRouting(userInfo);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      await handleRouting(userInfo);
    } catch (error) {
      const errorMessage = error.message;
      // Handle errors here if needed
      console.error("Google sign-in error:", errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
      {loading ? (
        <TrendySpinner /> // Display TrendySpinner while loading
      ) : (
        <Container
          className="justify-content-center align-items-center bg-dark text-white"
          style={{ padding: "10%", backgroundColor: "#1e1e1e" }}
        >
          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={10} md={8} lg={6}>
              <Card
                className="p-4"
                style={{ width: "100%", backgroundColor: "#1e1e1e" }}
              >
                <CardBody className="d-flex flex-column align-items-center">
                  <LogoSVG className="img-fluid" />
                  <hr className="my-4" style={{ width: "100%" }} />
                  <GoogleSignInButton
                    onClick={handleGoogleSignIn}
                    disabled={loading}
                    className="mb-3"
                    style={{ width: "100%" }}
                  >
                    {loading ? "Loading..." : "Sign In with Google"}
                  </GoogleSignInButton>

                  {/* Regular Sign-In Form */}
                  {/* Divider lines */}
                  <div style={dividerStyles}></div>
                  <h2
                    className="title text-center mb-1 text-white"
                    style={orTextStyles}
                  >
                    {" "}
                    or{" "}
                  </h2>
                  <div style={dividerStyles}></div>

                  <Form className="w-100">
                    <FormGroup>
                      <div style={{ marginBottom: "5px" }}>
                        <InputWithPlaceholderColor
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="input mb-3"
                        />
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <InputWithPlaceholderColor
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="input mb-3"
                        />
                      </div>

                      {error && <Alert color="danger">{error}</Alert>}
                      <Button
                        color="primary"
                        onClick={handleSignIn}
                        className="button mb-3 w-100"
                        style={{ maxWidth: "100%" }}
                      >
                        Sign In
                      </Button>
                    </FormGroup>
                  </Form>
                  <CardButton
                    to="/roleSelection"
                    text=" Don't have an account? Sign Up"
                  />
                  <CardButton to="/PasswordRecovery" text="Recover Password" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, { setUserInfo })(SignInScreen);

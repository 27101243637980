import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledLink = styled.a`
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  /* Initial size */
  padding: 0px 5px;
  font-size: 16px;
  border-radius: 5px;

  &:hover {
    background-color: transparent;
    padding: 0px 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer; /* Add pointer cursor on hover */
  }
`;

const CardButton = ({ to, text }) => {
  const navigate = useNavigate();

  const navigateToDestination = () => {
    navigate(to);
  };

  return <StyledLink onClick={navigateToDestination}>{text}</StyledLink>;
};

export default CardButton;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { db } from "../firebase";
import { connect } from "react-redux";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

// Hashmap of preset zones for different organization types
const presetZones = {
  "Schools and universities": ["Classroom A", "Library B", "Lab C"],
  "Office buildings and corporate campuses": [
    "Conference Room X",
    "Executive Suite Y",
    "Research Area Z",
  ],
  "Hospitals and medical facilities": ["Classroom A", "Library B", "Lab C"],
  "Retail stores and shopping centers": [
    "Conference Room X",
    "Executive Suite Y",
    "Research Area Z",
  ],
  "Construction sites": ["Classroom A", "Library B", "Lab C"],
  Other: [],
  // Add more organization types and corresponding preset zones as needed
};

const ZoneCreationScreen = ({ userInfo }) => {
  const [zoneInput, setZoneInput] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [orgType, setOrgType] = useState(""); // State to store organization type
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setZoneInput(e.target.value);
  };

  const handleAddZone = () => {
    if (zoneInput.trim() !== "") {
      setZoneList([...zoneList, zoneInput]);
      setZoneInput("");
    }
  };

  const handleDeleteZone = (index) => {
    const updatedZoneList = [...zoneList];
    updatedZoneList.splice(index, 1);
    setZoneList(updatedZoneList);
  };

  const handleSaveZones = async () => {
    try {
      const orgID = userInfo.orgID;
      const orgDoc = doc(db, "organization", orgID);

      // Update Firestore document with the zoneList
      await setDoc(orgDoc, { organizationZoneList: zoneList }, { merge: true });
      console.log("ZoneList saved to Firestore.");
      navigate("/profile");
    } catch (error) {
      console.error("Error saving zoneList to Firestore:", error);
    }
  };

  useEffect(() => {
    const fetchOrgType = async () => {
      try {
        const profileDoc = doc(db, "profiles", userInfo.uid);
        const profileSnapshot = await getDoc(profileDoc);

        if (profileSnapshot.exists()) {
          const orgID = userInfo.orgID;
          const orgDoc = doc(db, "organization", orgID);
          const orgSnapshot = await getDoc(orgDoc);

          if (orgSnapshot.exists()) {
            const fetchedOrgType = orgSnapshot.data().organizationType;
            setOrgType(fetchedOrgType);

            // Preload preset zones based on orgType
            if (presetZones.hasOwnProperty(fetchedOrgType)) {
              setZoneList(presetZones[fetchedOrgType]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching organization type:", error);
      }
    };

    fetchOrgType();
  }, [orgType, userInfo.uid, userInfo.orgID]);

  return (
    <div className="container mt-5">
      <h2>Create Zones</h2>
      <Card className="mt-3">
        <CardBody>
          <form>
            <FormGroup>
              <Label for="zoneInput">Enter Zone Name:</Label>
              <Input
                type="text"
                name="zoneInput"
                id="zoneInput"
                value={zoneInput}
                onChange={handleInputChange}
              />
            </FormGroup>
            <Button color="primary" onClick={handleAddZone}>
              Add Zone
            </Button>
          </form>
          <hr />
          <h4>Zone List</h4>
          <ListGroup>
            {zoneList.map((zone, index) => (
              <ListGroupItem key={index}>
                {zone}
                <Button
                  color="danger"
                  size="sm"
                  className="float-right"
                  onClick={() => handleDeleteZone(index)}
                >
                  Delete
                </Button>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
      <div className="mt-3">
        <h4>Organization Type: {orgType}</h4>
        <Button color="primary" onClick={handleSaveZones}>
          Save ZoneList to Firestore
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(ZoneCreationScreen);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB0GAqqRLI4xYXv-cyqczZqkLQ20-16_nU",
  authDomain: "hall-pass-app-ca1a4.firebaseapp.com",
  projectId: "hall-pass-app-ca1a4",
  storageBucket: "hall-pass-app-ca1a4.appspot.com",
  messagingSenderId: "375387056262",
  appId: "1:375387056262:web:07b13fc1158c7346e84907",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Enable offline persistence
try {
  enableIndexedDbPersistence(db);
} catch (error) {
  if (error.code === "failed-precondition") {
    console.log("Persistence failed: multiple tabs open.");
  } else if (error.code === "unimplemented") {
    console.log("Persistence failed: browser not supported.");
  }
}

export {
  app,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  db,
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
  sendPasswordResetEmail,
};

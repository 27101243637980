import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import LogoSVG from "./LogoSVG";

const HomePage = () => {
  return (
    <div
      className="bg-dark text-white"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <header className="header py-4" style={{ backgroundColor: "#1e1e1e" }}>
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg="12" className="text-center">
              <LogoSVG className="img-fluid" />
              <p className="lead">
                Rise above the chaos with innovative solutions.
              </p>
              <hr className="my-4 bg-light" />
              <p>
                Streamline your operations, boost productivity, and effectively
                manage.
              </p>
              <div className="d-flex justify-content-center gap-2">
                {" "}
                {/* Added a wrapper div */}
                <Button
                  color="#cefbf4"
                  className="btn-rounded btn-outline-light"
                  size="lg"
                  tag={Link}
                  to="/roleSelection"
                >
                  Get Started
                </Button>
                <Button
                  tag={Link}
                  to="/sign-in"
                  color="#cefbf4"
                  className="btn-rounded btn-outline-light"
                  size="lg"
                >
                  Login
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section
        className="features-section py-5"
        style={{ backgroundColor: "#1f1f1f" }}
      >
        <Container>
          <Row>
            <Col md="4">
              <Card className="bg-dark text-white border-0 shadow">
                <CardBody>
                  <CardTitle tag="h5" className="text-light">
                    Feature 1
                  </CardTitle>
                  <CardText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </CardText>
                  <Button
                    color="#cefbf4"
                    className="btn-rounded btn-outline-light"
                  >
                    Learn More
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="bg-dark text-white border-0 shadow">
                <CardBody>
                  <CardTitle tag="h5" className="text-light">
                    Feature 2
                  </CardTitle>
                  <CardText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </CardText>
                  <Button
                    color="#cefbf4"
                    className="btn-rounded btn-outline-light"
                  >
                    Learn More
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="bg-dark text-white border-0 shadow">
                <CardBody>
                  <CardTitle tag="h5" className="text-light">
                    Feature 3
                  </CardTitle>
                  <CardText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </CardText>
                  <Button
                    color="#cefbf4"
                    className="btn-rounded btn-outline-light"
                  >
                    Learn More
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="cta-section py-5"
        style={{ backgroundColor: "#2641b7" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="text-center">
              <h2 className="text-light">Get Started Today</h2>
              <p className="text-light">
                Sign up for our SaaS platform and start transforming your
                business.
              </p>
              <Button color="light" size="lg" className="btn-rounded">
                Start Free Trial
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <footer
        className="footer py-4 bg-dark text-light"
        style={{ backgroundColor: "#1e1e1e" }}
      >
        <Container>
          <Row>
            <Col md="9">
              <Nav className="justify-content-end">
                <NavItem>
                  <NavLink href="#" className="text-light btn-rounded">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className="text-light btn-rounded">
                    Features
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className="text-light btn-rounded">
                    Pricing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className="text-light btn-rounded">
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default HomePage;

// darkTheme.js
import { createGlobalStyle } from "styled-components";

const DarkTheme = createGlobalStyle`
  body {
    background-color: #2d2d2d;
    color: #fff;
  }

  .bg-dark {
    background-color: #343a40 !important;
  }

  .text-white {
    color: #fff !important;
  }

  .btn-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
  }

  .btn-primary:hover {
    background-color: #0062cc !important;
    border-color: #005cbf !important;
  }
`;

export default DarkTheme;

import React, { useEffect } from "react";
import { Provider, connect } from "react-redux"; // Import Provider
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//import HomeScreen from "./components/HomeScreen";
import HomePage from "./components/HomePage";
import RoleSelectionScreen from "./components/RoleSelectionScreen";
import ContactScreen from "./components/ContactScreen";
import AdminSignUpScreen from "./components/AdminSignUpScreen";
import StudentSignUpScreen from "./components/StudentSignUpScreen";
import SchoolRegistrationScreen from "./components/SchoolRegistrationScreen";
import SchoolRegistrationForm from "./components/SchoolRegistrationForm";
import SignInScreen from "./components/SignInScreen";
import ProfileScreen from "./components/ProfileScreen";
import PasswordRecoveryScreen from "./components/PasswordRecovery";
import OrganizationSelection from "./components/OrganizationSelection";
import ZoneCreationScreen from "./components/ZoneCreationScreen";
import store from "./redux/store"; // Import the Redux store
import AccountScreen from "./components/AccountScreen";
import { setUserInfo, clearUserInfo } from "./redux/userActions";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

// Async function to fetch user data from Firestore
const fetchUserData = async (userId) => {
  const localStorageKey = `userData_${userId}`;

  // Check if user data exists in localStorage
  const cachedUserData = localStorage.getItem(localStorageKey);
  console.log("Cached user data:", cachedUserData); // Debugging statement

  if (cachedUserData) {
    console.log(
      "Retrieved user data from localStorage:",
      JSON.parse(cachedUserData)
    ); // Debugging statement
    return JSON.parse(cachedUserData);
  }

  const userDocRef = doc(db, "profiles", userId);
  const userDocSnap = await getDoc(userDocRef);
  console.log(
    "User data from user doc:",
    JSON.stringify(userDocSnap.data(), null, 2)
  );
  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    // Store user data in localStorage for caching
    localStorage.setItem(localStorageKey, JSON.stringify(userData));
    console.log("Stored user data in localStorage:", userData); // Debugging statement
    return userData;
  }
  return null;
};

const App = ({ setUserInfo, clearUserInfo }) => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in, retrieve user data from Firestore and set authentication state
        const userDataFromFirestore = await fetchUserData(user.uid);
        if (userDataFromFirestore) {
          const userData = {
            uid: user.uid,
            email: user.email,
            name: userDataFromFirestore.name,
            role: userDataFromFirestore.role,
            orgID: userDataFromFirestore.orgID,
            // Add other user data properties as needed
          };
          setUserInfo(userData); // Update Redux state with user data
        }
      } else {
        // No user is signed in, clear authentication state
        clearUserInfo();
      }
    });

    return () => {
      // Unsubscribe from Firebase Auth state changes when component unmounts
      unsubscribe();
    };
  }, [setUserInfo, clearUserInfo]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/roleSelection" element={<RoleSelectionScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/sign-in" element={<SignInScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/admin-signup" element={<AdminSignUpScreen />} />
        <Route path="/student-signup" element={<StudentSignUpScreen />} />
        <Route
          path="/school-registration"
          element={<SchoolRegistrationScreen />}
        />
        <Route path="/PasswordRecovery" element={<PasswordRecoveryScreen />} />
        <Route path="/register-school" element={<SchoolRegistrationForm />} />
        <Route path="/zone-creation" element={<ZoneCreationScreen />} />
        <Route
          path="/organization-selection"
          element={<OrganizationSelection />}
        />
        <Route path="/account" element={<AccountScreen />} />
      </Routes>
    </Router>
  );
};

const mapDispatchToProps = {
  setUserInfo,
  clearUserInfo,
};

const ConnectedApp = connect(null, mapDispatchToProps)(App);

const WrappedApp = () => (
  <Provider store={store}>
    <ConnectedApp />
  </Provider>
);

export default WrappedApp;

// redux/userActions.js

// Define action types
export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

// Action creator function to set user info
export const setUserInfo = (userInfo) => ({
  type: SET_USER_INFO,
  payload: userInfo,
});

// Action creator function to clear user info
export const clearUserInfo = () => ({
  type: CLEAR_USER_INFO,
});

import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { auth } from "../firebase";
import { getCheckoutUrl } from "./Payment";
import TrendySpinner from "./TrendySpinner";
import LogoSVG from "./LogoSVG";

const AccountScreen = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const upgradeToTier = useCallback(async (priceId) => {
    try {
      setIsLoading(true);
      const checkoutUrl = await getCheckoutUrl(priceId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error upgrading to premium:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    auth.signOut();
    navigate("/");
  }, [navigate]);

  return (
    <>
      {isLoading && <TrendySpinner />} {/* Render TrendySpinner if isLoading */}
      <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
        <Container
          className="justify-content-center align-items-center bg-dark text-white"
          style={{ padding: "10%", backgroundColor: "#1e1e1e" }}
        >
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Card
                className="shadow-lg text-white"
                style={{ width: "100%", backgroundColor: "#1e1e1e" }}
              >
                <CardBody className="d-flex flex-column align-items-center text-white">
                  <LogoSVG className="img-fluid" />
                  <Row className="mb-3"></Row>
                  <Row className="mb-3">
                    <Col xs={12} md={4} className="mb-4">
                      <Card
                        className="h-100 shadow text-white"
                        style={{ width: "100%", backgroundColor: "#1e1e1e" }}
                      >
                        <CardBody>
                          <CardTitle tag="h5">Small Organization</CardTitle>
                          <CardText>$149.99/month</CardText>
                          <CardText>
                            <ul>
                              <li>Up to 100 admin users</li>
                              <li>
                                Real-time streaming updates on secured access
                                passes
                              </li>
                              <li>On-demand data and reporting</li>
                              {/* Add more features as needed */}
                            </ul>
                          </CardText>
                          <Button
                            color="#cefbf4"
                            size="lg"
                            className="btn-rounded btn-outline-light"
                            onClick={() =>
                              upgradeToTier("price_1PCrbWHzISrKAr4wK4SzrLEG")
                            }
                            block
                            disabled={isLoading}
                          >
                            Subscribe
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} md={4} className="mb-4">
                      <Card
                        className="h-100 shadow text-white"
                        style={{ width: "100%", backgroundColor: "#1e1e1e" }}
                      >
                        <CardBody>
                          <CardTitle tag="h5">Medium Organization</CardTitle>
                          <CardText>$399.99/month</CardText>
                          <CardText>
                            <ul>
                              <li>Up to 500 admin users</li>
                              <li>
                                Real-time streaming updates on secured access
                                passes
                              </li>
                              <li>On-demand data and reporting</li>
                              {/* Add more features as needed */}
                            </ul>
                          </CardText>
                          <Button
                            color="#cefbf4"
                            size="lg"
                            className="btn-rounded btn-outline-light"
                            onClick={() =>
                              upgradeToTier("price_1PCrgRHzISrKAr4wBsaXFn5o")
                            }
                            block
                            disabled={isLoading}
                          >
                            Subscribe
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} md={4} className="mb-4">
                      <Card
                        className="h-100 shadow text-white"
                        style={{ width: "100%", backgroundColor: "#1e1e1e" }}
                      >
                        <CardBody>
                          <CardTitle tag="h5">Large Organization</CardTitle>
                          <CardText>$799.99/month</CardText>
                          <CardText>
                            <ul>
                              <li>Up to 1000 admin users</li>
                              <li>
                                Real-time streaming updates on secured access
                                passes
                              </li>
                              <li>On-demand data and reporting</li>
                              {/* Add more features as needed */}
                            </ul>
                          </CardText>
                          <Button
                            color="#cefbf4"
                            size="lg"
                            className="btn-rounded btn-outline-light"
                            onClick={() =>
                              upgradeToTier("price_1PCrhkHzISrKAr4wtHdQg8Iz")
                            }
                            block
                            disabled={isLoading}
                          >
                            Subscribe
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12}></Col>
                  </Row>
                  <Col xs={12} className="text-center">
                    <Button
                      color="#cefbf4"
                      size="lg"
                      className="btn-rounded btn-outline-light"
                      onClick={signOut}
                    >
                      Sign Out
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AccountScreen;

import React from "react";

const ContactScreen = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>
        This is a basic contact screen for testing purposes. You can add your
        contact information or form here.
      </p>
    </div>
  );
};

export default ContactScreen;

import React from "react";
import { Button } from "reactstrap";
import { auth } from "../firebase"; // Assuming you have initialized Firebase and imported 'auth'
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the user using Firebase Auth
      console.log("User logged out successfully.");
      navigate("/home");
      // You can add any additional logic here after the user is logged out
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Button color="danger" onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;

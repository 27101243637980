// InputWithPlaceholderColor.js
import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  width: 100%;
  position: relative;

  ::placeholder {
    color: ${(props) => props.placeholderColor || "#999"};
    font-style: italic;
    opacity: 0.8;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

const InputWithPlaceholderColor = ({
  type,
  placeholder,
  value,
  onChange,
  placeholderColor,
}) => {
  return (
    <StyledInput
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      placeholderColor={placeholderColor}
    />
  );
};

export default InputWithPlaceholderColor;

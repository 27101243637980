// rootReducer.js
import { combineReducers } from "redux";
import roleReducer from "./roleReducer";
import userReducer from "./userReducer"; // Import userReducer

const rootReducer = combineReducers({
  role: roleReducer,
  user: userReducer, // Add userReducer
});

export default rootReducer;

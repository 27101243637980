// redux/userReducer.js

// Import action type
import { SET_USER_INFO, CLEAR_USER_INFO } from "./userActions";

// Initial state for user information
const initialState = {
  userInfo: null,
};

// Redux reducer to handle user info
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: null,
      };
    default:
      return state;
  }
};

export default userReducer;

// TrendySpinner.js
import React from "react";
import { Spinner, Container } from "reactstrap";

const TrendySpinner = React.memo(() => {
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spinner
        color="primary"
        style={{
          width: "3rem",
          height: "3rem",
          borderWidth: "0.5em",
          animation: "spinner-border 0.75s linear infinite",
        }}
      >
        Loading...
      </Spinner>
    </Container>
  );
});

export default TrendySpinner;

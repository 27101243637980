import React, {
  useState,
  useEffect,
  Suspense,
  lazy,
  startTransition,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Navbar,
  Collapse,
  Nav,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import TrendySpinner from "./TrendySpinner";
import LogoutButton from "./LogoutButton";
import DarkTheme from "./darkTheme";
import SmallLogo from "./SmallLogo";

const HallPassCard = lazy(() => import("./HallPassCard"));
const RequestCard = lazy(() => import("./RequestCard"));

const ProfileScreen = ({ userInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const cachedUserInfo = useMemo(() => {
    const cachedData = localStorage.getItem("userInfo");
    return cachedData ? JSON.parse(cachedData) : null;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userInfo && userInfo.uid) {
          setLoading(false);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (cachedUserInfo) {
      setLoading(false);
    }
  }, [cachedUserInfo]);

  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    startTransition(() => {
      // Logout logic here
      LogoutButton.handleLogout();
    });
  };

  if (loading || !userInfo) {
    return <TrendySpinner fullHeight />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <DarkTheme />
      {/* Navbar */}
      <Navbar light expand="md" className="mb-3">
        <Container>
          <Col xs={12} sm={6} className="text-right">
            <SmallLogo className="mr-5" />
            <Button color="primary" onClick={toggleOffcanvas} size="sm">
              Profile
            </Button>
          </Col>
          <Collapse navbar>
            <Nav className="ml-auto" navbar></Nav>
          </Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container className="mt-3">
        <Offcanvas
          isOpen={isOpen}
          toggle={toggleOffcanvas}
          backdrop={false}
          scrollable
        >
          <OffcanvasHeader toggle={toggleOffcanvas}>Profile</OffcanvasHeader>
          <OffcanvasBody>
            <div className="mb-3">
              <strong>Name:</strong> {userInfo.name || ""}
            </div>
            <div className="mb-3">
              <strong>Email:</strong> {userInfo.email || ""}
            </div>
            <div className="mb-3">
              <strong>Role:</strong> {userInfo.role || ""}
            </div>
            <div className="mb-3">
              <strong>Organization Id#:</strong> {userInfo.orgID || ""}
            </div>
            <div className="text-center">
              <LogoutButton onLogout={handleLogout} />
            </div>
          </OffcanvasBody>
        </Offcanvas>

        <Suspense fallback={<TrendySpinner fullHeight />}>
          <Row className="justify-content-center">
            <Col xs={12} md={4}>
              <Row>
                <Col xs={12} className="mb-3">
                  {userInfo.role === "student" ? (
                    <RequestCard userId={userInfo} />
                  ) : null}
                </Col>
                <Col xs={12} className="mb-3">
                  <HallPassCard userId={userInfo} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Suspense>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(ProfileScreen);

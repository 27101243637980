import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const checkIfOrganizationExists = (idNumber) => {
  const organizationRef = doc(db, "organization", idNumber);

  return new Promise((resolve, reject) => {
    getDoc(organizationRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          resolve(true); // Organization document exists
        } else {
          resolve(false); // Organization document does not exist
        }
      })
      .catch((error) => {
        reject(error); // Error occurred while fetching document
      });
  });
};

export default checkIfOrganizationExists;

import { db, auth } from "../firebase";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getCheckoutUrl = async (priceId) => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("User is not authenticated");

    const checkoutSessionRef = collection(
      db,
      "customers",
      userId,
      "checkout_sessions"
    );

    const docRef = await addDoc(checkoutSessionRef, {
      price: priceId,
      success_url: `${window.location.origin}/organization-selection`,
      cancel_url: window.location.origin,
    });

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data() || {};
        if (error) {
          unsubscribe();
          reject(new Error(`An error occurred: ${error.message}`));
        }
        if (url) {
          console.log("Stripe Checkout URL:", url);
          unsubscribe();
          resolve(url);
        }
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPortalUrl = async () => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("User is not authenticated");

    const functions = getFunctions();
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: userId,
      returnUrl: window.location.origin,
    });

    const { url } = data || {};
    if (url) {
      console.log("Reroute to Stripe portal: ", url);
      return url;
    } else {
      throw new Error("No URL returned");
    }
  } catch (error) {
    console.error(error);
  }
};

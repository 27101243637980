import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  Tooltip,
  Alert,
} from "reactstrap";
import { createUserWithEmailAndPassword, db, auth } from "../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { setUserInfo } from "../redux/userActions";
import { useNavigate } from "react-router-dom";
import LogoSVG from "./LogoSVG";
import InputWithPlaceholderColor from "./InputWithPlaceholderColor";

const StudentSignUpScreen = ({ role, setUserInfo }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleSignUp = async () => {
    try {
      // Create a user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the user object from the userCredential
      const user = userCredential.user;

      // Set user profile with role
      await setDoc(doc(db, "profiles", user.uid), {
        name: name,
        email: email,
        role: role,
        // Add additional fields as needed
      });

      // Fetch user data from Firestore and set it in Redux state
      const userDocSnap = await getDoc(doc(db, "profiles", user.uid));
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUserInfo({
          ...userData,
          uid: user.uid, // Add user's UID to the userInfo object
        });
      }

      console.log("Profile created successfully!");
      navigate("/school-registration");
    } catch (error) {
      console.error("Error creating profile:", error.message);
      // Set error message state based on the error code/message
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage("User with this email already exists.");
      } else if (error.code === "auth/weak-password") {
        setErrorMessage("Password should be at least 6 characters long.");
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
      <Container
        className="justify-content-center align-items-center bg-dark text-white"
        style={{ padding: "10%", backgroundColor: "#1e1e1e" }}
      >
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card
              className="p-4"
              style={{ width: "100%", backgroundColor: "#1e1e1e" }}
            >
              <CardBody className="d-flex flex-column align-items-center text-white">
                <LogoSVG className="img-fluid" />
                <Row className="mb-3"></Row>
                <FormGroup>
                  <InputWithPlaceholderColor
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <InputWithPlaceholderColor
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <InputWithPlaceholderColor
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
                {/* Display error message if exists */}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <Button
                  color="#cefbf4"
                  size="lg"
                  id="TooltipProfile"
                  className="btn-rounded btn-outline-light"
                  onClick={handleSignUp}
                >
                  Create Profile
                </Button>
                <Tooltip
                  isOpen={tooltipOpen}
                  target="TooltipProfile"
                  toggle={toggle}
                >
                  Create your user profile to request access passes within your
                  organization.
                </Tooltip>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSignUpScreen);

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import LogoSVG from "./LogoSVG"; // Assuming LogoSVG component is correctly imported
import { sendPasswordResetEmail } from "../firebase";
import InputWithPlaceholderColor from "./InputWithPlaceholderColor";
import CardButton from "./CardButton";

const PasswordRecoveryScreen = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handlePasswordRecovery = async () => {
    try {
      if (!email.trim()) {
        setError("Invalid Email\nPlease provide a valid email address.");
        return;
      }

      // Simulating sending password reset email
      await sendPasswordResetEmail(email);
      setSuccessMessage(
        "Password Reset Email Sent\nPlease check your email for instructions."
      );
      setError("");
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      setError("Error\nFailed to send password reset email. Please try again.");
    }
  };

  return (
    <div
      className="bg-dark text-white"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <Container
        className="justify-content-center align-items-center bg-dark text-white"
        style={{
          padding: "10%",
          backgroundColor: "#1e1e1e",
        }}
      >
        <Row className="d-flex justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card
              className="p-4"
              style={{ width: "100%", backgroundColor: "#1e1e1e" }}
            >
              <CardBody className="flex-column align-items-center">
                <div className="text-center mb-4">
                  <LogoSVG className="img-fluid" />
                </div>
                <Form>
                  <FormGroup>
                    <div style={{ marginBottom: "10px" }}>
                      <InputWithPlaceholderColor
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input mb-3"
                      />
                    </div>
                    {error && <Alert color="danger">{error}</Alert>}
                    {successMessage && (
                      <Alert color="success">{successMessage}</Alert>
                    )}
                    <Button
                      color="primary"
                      onClick={handlePasswordRecovery}
                      className="w-100"
                    >
                      Recover Password
                    </Button>
                  </FormGroup>
                </Form>
                <div className="text-center mt-5">
                  <p className="b-0 text-white">
                    <CardButton to="/sign-in" text="Remember your password? Sign In" />
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordRecoveryScreen;

// SchoolRegistrationForm.js
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { updateDoc, doc, setDoc, getDoc } from "firebase/firestore";
import { setUserInfo } from "../redux/userActions";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import LogoSVG from "./LogoSVG";

const generateUniqueId = () => {
  const timestamp = Date.now();
  const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000);
  const uniqueId = `${timestamp}${uniqueIdentifier}`;
  return uniqueId;
};

const SchoolRegistrationForm = ({ setUserInfo }) => {
  const uid = auth.currentUser?.uid;
  const [schoolName, setSchoolName] = useState("");
  const [schoolCity, setSchoolCity] = useState("");
  const [schoolState, setSchoolState] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "schoolName":
        setSchoolName(value);
        break;
      case "schoolCity":
        setSchoolCity(value);
        break;
      case "schoolState":
        setSchoolState(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    // Handle form validation
    if (!schoolName || !schoolCity || !schoolState) {
      // Show error message or alert
      return;
    }

    // Handle submission logic here
    const generatedUniqueId = generateUniqueId();
    console.log("Submitted school ID:", generatedUniqueId);
    console.log("Submitted school Name:", schoolName);
    console.log("Submitted school City:", schoolCity);
    console.log("Submitted school State:", schoolState);

    // Create a document in the "schools" collection
    const schoolsRef = doc(db, "schools", generatedUniqueId);
    await setDoc(schoolsRef, {
      schoolName: schoolName,
      schoolCity: schoolCity,
      schoolState: schoolState,
    });

    try {
      const profileRef = await doc(db, "profiles", uid);
      await updateDoc(profileRef, {
        schoolID: generatedUniqueId,
      });
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }

    // Fetch user data from Firestore and set it in Redux state
    const userDocSnap = await getDoc(doc(db, "profiles", uid));
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      console.log("user data on school reg form:", userData);
      setUserInfo({ uid, ...userData });
    }

    // Clear form fields after submission
    setSchoolName("");
    setSchoolCity("");
    setSchoolState("");

    navigate("/profile");
  };

  return (
    <div
      className="bg-dark text-white"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <Container
        className="justify-content-center align-items-center bg-dark text-white"
        style={{
          padding: "10%",
          backgroundColor: "#1e1e1e",
        }}
      >
        <Row className="d-flex justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card
              className="p-4"
              style={{ width: "100%", backgroundColor: "#1e1e1e" }}
            >
              <CardBody className="d-flex flex-column align-items-center text-white">
                <LogoSVG className="img-fluid" />
                <hr className="my-4" style={{ width: "100%" }} />
                <Form className="w-100">
                  <FormGroup>
                    <Label for="schoolName">School Name</Label>
                    <Input
                      type="text"
                      id="schoolName"
                      name="schoolName"
                      placeholder="Enter school name"
                      value={schoolName}
                      onChange={handleChange}
                      className="mb-3"
                    />
                    <Label for="schoolCity">City</Label>
                    <Input
                      type="text"
                      id="schoolCity"
                      name="schoolCity"
                      placeholder="Enter school city"
                      value={schoolCity}
                      onChange={handleChange}
                      className="mb-3"
                    />
                    <Label for="schoolState">State</Label>
                    <Input
                      type="text"
                      id="schoolState"
                      name="schoolState"
                      placeholder="Enter school state"
                      value={schoolState}
                      onChange={handleChange}
                      className="mb-3"
                    />
                  </FormGroup>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    className="w-100"
                  >
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolRegistrationForm);
